import React, { ReactNode } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

import { Container } from './footer.styles';

export const Footer = () => {
  const intl = useIntl();
  const {
    site: {
      siteMetadata: { title, contact },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            contact {
              company
            }
          }
        }
      }
    `
  );

  return (
    <Container>
      <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <a href="/" className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
          <StaticImage
            className="fill-current w-10 h-10"
            src="../../../images/wizman-logo-sprzet-pomiarowy.png"
            alt="Wizman"
          />
          <span className="ml-3 text-xl">{contact.company}.eu</span>
        </a>
        <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
          © {new Date().getFullYear()} {intl.formatMessage({ id: 'title' })}
        </p>
      </div>
    </Container>
  );
};
