import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { useAnimatedNavigationToggler } from '../navigation.hooks';
import { Container, Button, Line, LinesContainer } from './hamburgerMenu.styles';

interface HamburgerMenuProps {
  isOpen: boolean;
  onToggleMenu: () => void;
}

export const HamburgerMenu = ({ isOpen, onToggleMenu }: HamburgerMenuProps) => {
  const topLineClasses = classNames({
    '-translate-y-1.5': !isOpen,
    'rotate-45': isOpen,
  });
  const bottomLineClasses = classNames({
    'translate-y-1.5': !isOpen,
    '-rotate-45': isOpen,
  });
  const middleLineClasses = classNames({
    'opacity-0': isOpen,
  });

  return (
    <Container>
      <Button type="button" onClick={onToggleMenu}>
        <LinesContainer>
          <Line aria-hidden="true" className={topLineClasses} />
          <Line aria-hidden="true" className={middleLineClasses} />
          <Line aria-hidden="true" className={bottomLineClasses} />
        </LinesContainer>
        <span className="sr-only">Click to open main menu</span>
      </Button>
    </Container>
  );
};
