import React, { ReactNode } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

import { Container, SubmitButton } from './contactFormSection.styles';

export interface ContactFormSectionProps {
  children?: ReactNode;
}

export const ContactFormSection = () => {
  const { locale, ...intl } = useIntl();
  const {
    site: {
      siteMetadata: { contact },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            contact {
              company
              phone {
                display
                linkNumber
              }
              email
              address
              address2
            }
          }
        }
      }
    `
  );

  return (
    <section id={intl.formatMessage({ id: 'contactForm.section_id' })} className="text-gray-600 body-font">
      <div className="container relative flex flex-col md:flex-row">
        <aside className="px-5 pt-24 lg:py-24 mx-auto w-full lg:w-1/3 md:w-1/2 text-left">
          <h2 className="mb-5 text-white font-bold text-xl">
            {contact.company} - {intl.formatMessage({ id: 'contactForm.company_sort_description' })}
          </h2>
          <p className="mb-8 leading-relaxed text-gray-100">
            <strong>{intl.formatMessage({ id: 'contactForm.headquarters_label' })}</strong>
            <br />
            {locale === 'pl' && 'ul.'} {contact.address},
            <br />
            {contact.address2}
          </p>
          <p className="mb-8 leading-relaxed text-gray-100">{intl.formatMessage({ id: 'contactForm.description' })}</p>
          <a href={contact.phone.linkNumber} className="flex items-center text-white font-bold mb-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 mr-2 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
              />
            </svg>
            <span>{contact.phone.display}</span>
          </a>

          <a href={`to:${contact.email}`} className="flex items-center text-white font-bold mb-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 mr-2 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
              />
            </svg>
            <span>{contact.email}</span>
          </a>
          <p className="leading-relaxed text-gray-100">{locale === 'pl' && 'Możliwość wystawienia faktury VAT'}</p>
        </aside>
        <div className="lg:px-8 mx-auto w-full lg:w-2/3 md:w-1/2">
          <div className="w-full mx-auto">
            <form className="flex flex-wrap my-16 px-4 py-8 bg-white rounded">
              <div className="w-full px-2 text-left">
                <h3 className="mb-5 text-gray-900 font-bold text-xl">
                  {intl.formatMessage({ id: 'contactForm.title' })}
                </h3>
                <p className="mb-8 leading-relaxed text-gray-700">{intl.formatMessage({ id: 'contactForm.intro' })}</p>
              </div>
              <div className="p-2 w-full lg:w-1/2 text-left">
                <div className="relative">
                  <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                    {intl.formatMessage({ id: 'contactForm.name_surname_label' })}
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    required
                    className="w-full bg-gray-50 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full lg:w-1/2 text-left">
                <div className="relative">
                  <label htmlFor="email" className="leading-7 text-sm text-gray-600">
                    {intl.formatMessage({ id: 'contactForm.email_label' })}
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full text-left">
                <div className="relative">
                  <label htmlFor="message" className="leading-7 text-sm text-gray-600">
                    {intl.formatMessage({ id: 'contactForm.message_label' })}
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                </div>
              </div>
              <div className="p-2 w-full">
                <SubmitButton type="submit">{intl.formatMessage({ id: 'contactForm.submit' })}</SubmitButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
