import React, { useState, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

import { HamburgerMenu } from './hamburgerMenu';
import {
  Container,
  Logo,
  MobileNavigationContainer,
  DesktopNavigationContainer,
  MobileNavigation,
  NavLinkList,
  NavLink,
  PrimaryLink,
  LangList,
  LangLink,
  ActiveLangLink,
  NavigationBackground,
} from './navigation.styles';
import { useAnimatedNavigationToggler } from './navigation.hooks';
import useWindowListener from '../../hooks/useWindowListener.hook';

const HeaderLogo = () => (
  <Logo href="/">
    <StaticImage
      className="fill-current h-16 w-16"
      src="../../../images/wizman-logo-sprzet-pomiarowy.png"
      alt="Wizman"
    />
  </Logo>
);

const links = (isMobile = false) => {
  const { locale, ...intl } = useIntl();
  return [
    <NavLinkList middle={!isMobile} mobile={isMobile} key={1}>
      <NavLink to={intl.formatMessage({ id: 'menu.about_us.link' })}>
        {intl.formatMessage({ id: 'menu.about_us.title' })}
      </NavLink>
      <NavLink to={intl.formatMessage({ id: 'menu.measuring_tools.link' })}>
        {intl.formatMessage({ id: 'menu.measuring_tools.title' })}
      </NavLink>
      {locale === 'pl' && (
        <NavLink to={intl.formatMessage({ id: 'menu.portable_boring_machine.link' })}>
          {intl.formatMessage({ id: 'menu.portable_boring_machine.title' })}
        </NavLink>
      )}
    </NavLinkList>,
    <NavLinkList mobile={isMobile} key={2}>
      <PrimaryLink to={intl.formatMessage({ id: 'menu.contact.link' })}>
        {intl.formatMessage({ id: 'menu.contact.title' })}
      </PrimaryLink>
    </NavLinkList>,
  ];
};

export const Navigation = () => {
  const { locale } = useIntl();
  const { isOpen, toggleNavbar, animation } = useAnimatedNavigationToggler();
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const containerRef = useRef<HTMLBaseElement>(null);

  const checkIsScrolled = () => {
    const navigationRect = containerRef.current?.getBoundingClientRect();
    const navigationHeight = navigationRect?.height || 0;
    const heroRect = document.querySelector('#page-hero')?.getBoundingClientRect();
    const heroHeight = heroRect?.height || 0;
    const triggerHeight = heroHeight - navigationHeight;
    setIsScrolled(Boolean(pageYOffset > triggerHeight));
  };

  useWindowListener('scroll', checkIsScrolled, { throttle: 200 });

  const LinkToENVersion = locale === 'en' ? ActiveLangLink : LangLink;

  const LinkToPLVersion = locale === 'pl' ? ActiveLangLink : LangLink;

  return (
    <Container ref={containerRef}>
      <NavigationBackground scrolled={isScrolled} />
      <DesktopNavigationContainer>
        <HeaderLogo />
        {links()}
        <LangList>
          <LinkToENVersion to="/en/">EN</LinkToENVersion> / <LinkToPLVersion to="/">PL</LinkToPLVersion>
        </LangList>
      </DesktopNavigationContainer>
      <MobileNavigationContainer>
        <HeaderLogo />
        <MobileNavigation initial={{ x: '150%', display: 'none' }} animate={animation} transition={{ duration: 0.5 }}>
          {links(true)}
        </MobileNavigation>
        <HamburgerMenu isOpen={isOpen} onToggleMenu={toggleNavbar} />
      </MobileNavigationContainer>
    </Container>
  );
};
