import styled from 'styled-components';
import tw from 'tailwind-styled-components';

export const Container = tw.div``;

export const SubmitButton = tw.button`
  text-xl
  my-6
  md:text-sm
  md:ml-6
  md:my-0
  font-semibold
  tracking-wide
  transition
  duration-300
  cursor-pointer
  px-8
  py-3
  pb-3
  rounded
  bg-blue-500
  text-white
  hover:bg-blue-700
  border-b-0
`;
