import { useState } from 'react';
import { useAnimation, useCycle } from 'framer-motion';

export const useAnimatedNavigationToggler = () => {
  const animation = useAnimation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [x, cycleX] = useCycle('0%', '150%');

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    animation.start({ x: x, display: 'block' });
    cycleX();
  };

  return { toggleNavbar, isOpen, animation };
};
