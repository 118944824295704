import tw from 'tailwind-styled-components';

export const Container = tw.div`
  block
  z-20
  lg:hidden
`;

export const Button = tw.button`
  text-black w-10 h-10 relative focus:outline-none
`;

export const LinesContainer = tw.div`
  block
  w-5
  absolute
  left-1/2
  top-1/2
  transform
  -translate-x-1/2
  -translate-y-1/2
`;

export const Line = tw.span`
  block
  absolute
  h-0.5
  w-5
  bg-current
  transform
  transition
  duration-500
  ease-in-out
`;
