import styled, { css, StyledProps, DefaultProps } from 'styled-components';
import tw from 'tailwind-styled-components';
import { motion } from 'framer-motion';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export const Container = tw.nav`
  w-full
  flex
  items-center
  justify-between
  flex-wrap
  p-6
  fixed
  z-10
  top-0
`;

export const NavigationBackground = tw.div`
  absolute
  -z-1
  top-0
  left-0
  h-full
  w-full
  bg-white
  transition
  duration-300
  ${(p: { scrolled: boolean }) => (p.scrolled ? '' : 'md:opacity-70')}
`;

export const Logo = tw.a`
  flex
  items-center
  flex-shrink-0
  text-white
  mr-6
  absolute
  left-6
`;

export const MobileNavigationContainer = tw.nav`
  flex
  md:hidden
  flex-1
  items-center
  justify-end
`;

export const DesktopNavigationContainer = tw.nav`
  hidden
  md:flex
  flex-1
  justify-between
  items-center
`;

export const LangList = tw.div`
  text-xl
  my-6
  md:text-sm
  md:ml-6
  md:my-0
  pb-1
`;

export const LangLink = tw(AnchorLink)`
  text-xl
  my-6
  md:text-sm
  md:mx-1
  md:my-0
  font-semibold
  tracking-wide
  transition
  duration-300
  pb-1
  cursor-pointer
  md:hover:text-blue-500
`;

export const ActiveLangLink = tw(AnchorLink)`
  text-xl
  my-6
  md:text-sm
  md:mx-1
  md:my-0
  font-semibold
  tracking-wide
  transition
  duration-300
  pb-1
  text-blue-500
`;

export const NavLink = tw(AnchorLink)`
  text-xl
  my-6
  md:text-sm
  md:mx-6
  md:my-0
  font-semibold
  tracking-wide
  transition
  duration-300
  pb-1
  border-b-2
  border-transparent
  cursor-pointer
  md:hover:border-blue-500
  md:hover:text-blue-500
`;

export const PrimaryLink = tw(AnchorLink)`
  text-xl
  my-6
  md:text-sm
  md:ml-6
  md:my-0
  font-semibold
  tracking-wide
  transition
  duration-300
  cursor-pointer
  px-8
  py-3
  pb-3
  rounded
  bg-blue-500
  text-white
  hover:bg-blue-700
  border-b-0
`;

export const NavLinkList = tw.div`
  flex
  ${(p: { middle: boolean }) => (p.middle ? 'flex-1 justify-end' : '')};
  ${(p: { mobile: boolean }) => (p.mobile ? 'flex-col items-center mt-6' : '')}
`;

export const MobileNavigation = tw(motion.div)`
  lg:hidden
  z-10
  fixed
  h-full
  top-0
  inset-x-0
  p-8
  text-center 
  ext-gray-900
  bg-white
`;

// export interface ButtonTheme extends DefaultProps {
//   mode: ButtonType;
//   disabled: boolean;
// }

// const primaryButtonStyle = css`
//   background: ${themeColor(Color.PRIMARY)};
// `;

// export const Container = styled.button<StyledProps<ButtonComponentProps>>`
//   padding: ${themeSize(Size.CONTENT_VERTICAL_PADDING)}px ${themeSize(Size.CONTENT_HORIZONTAL_PADDING)}px;
//   border: ${themeBorder(Border.REGULAR)};
//   ${styleSwitcher('mode', {
//     [ButtonType.PRIMARY]: primaryButtonStyle,
//     [ButtonType.SECONDARY]: secondaryButtonStyle,
//   })};
//   ${styleWhenTrue<ButtonComponentProps>(propOr(false, 'disabled'), disabledButtonStyle)};
// `;
